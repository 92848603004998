
import Vue from "vue";

export default Vue.extend({
  name: "CallbackComponent",
  mounted(): void {
    this.$store
      .dispatch("oidcStore/oidcSignInCallback")
      .then(async (redirectPath: string) => {
        this.$router.push(redirectPath).catch(() => {
          this.$router.push("/Error");
        });
      })
      .catch(() => {
        this.$router.push("/Error");
      });
  },
});
